import {useEffect, useState} from "react";
import usePrevious from "v4/hooks/usePrevious";

export default function useTableThead(theadData, onSort) {
    const [currentSortHeader, setCurrentSortHeader] = useState(null);
    const [sortOrders, setSortOrders] = useState({});

    const previousTheadData = usePrevious(theadData);
    useEffect(() => {
        if (JSON.stringify(theadData) === JSON.stringify(previousTheadData)) return;

        setSortOrders(() => theadData.reduce((acc, header) => {
            const key = typeof header === 'object' ? header.key : header;
            acc[key] = 'desc'; // default sort order is desc to be asc on first click
            return acc;
        }, {}));
    }, [previousTheadData, theadData]);

    const onCellSort = (header, newSortOrder) => {
        setSortOrders(prevSortOrders => {
            onSort && onSort(header, newSortOrder);
            setCurrentSortHeader(header);

            return Object.keys(prevSortOrders).reduce((acc, previousKey) => {
                acc[previousKey] = previousKey === header ? newSortOrder : 'desc';
                return acc;
            }, {});
        });
    };

    return {sortOrders, onCellSort, currentSortHeader};
}
