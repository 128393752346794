import {useEffect, useRef, useState} from "react";
import {generateUrl} from "v4/services/api.service";
import {USER_SHOW_LIST} from "v4/data/apiRoutes";
import UserService from "api/user/user";
import {
    createActionTagSpan
} from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/utils/actionTagUtils";

export default function useUserList(editor, toggleCanUseKeyboard) {
    const listRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [users, setUsers] = useState();

    const removeList = () => {
        toggleCanUseKeyboard(true);
        editor.focus();

        if (!listRef.current?.parentElement) return;

        listRef.current.parentElement.remove();
    }

    useEffect(() => {
        fetch(generateUrl(USER_SHOW_LIST, {customerId: UserService.getCustomerId()}))
            .then(response => response.json())
            .then(({"hydra:member": usersList}) => setUsers(usersList))
    }, []);

    useEffect(() => {
        if (!users || !listRef.current) return;

        // Get caret position
        const {top, left, right} = editor.selection.getRng().getClientRects()[0];

        // Get the header height .tox-editor-header
        const headerHeight = editor.container.querySelector('.tox-editor-header').offsetHeight;

        listRef.current.style.setProperty('--caret-top', `${headerHeight + top}px`);

        const {left: editorLeft} = editor.container.getBoundingClientRect();
        if (editorLeft + right + listRef.current.offsetWidth > window.innerWidth) {
            listRef.current.style.setProperty('--caret-left', `${left}px`);
        } else {
            listRef.current.style.setProperty('--caret-right', `${right}px`);
        }

        // Focus the search input
        listRef.current.querySelector('input').focus();
    }, [editor.container, editor.selection, users]);

    const onUserSelect = (userId, userFullname, userEmail) => {
        removeList();
        // Select the last character before the caret to replace it with the tag (the @ character)
        const newRange = editor.selection.getRng().cloneRange();
        newRange.setStart(newRange.startContainer, newRange.startOffset - 1);
        editor.selection.setRng(newRange);
        const actionTagSpan = createActionTagSpan({
            userId,
            userFullname,
            userEmail,
        }, editor);
        editor.selection.setNode(actionTagSpan);
    }

    const handleBlurList = (e) => {
        if (e.relatedTarget) return;

        removeList();
    }

    const handleKeyboardNavigation = (e) => {
        const focusedItem = document.activeElement;
        const isInputFocused = focusedItem?.tagName === 'INPUT';

        const nextItem = focusedItem.nextElementSibling;
        const previousItem = focusedItem.previousElementSibling;

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            return nextItem && nextItem.focus();
        }

        if (e.key === 'ArrowUp' && previousItem) {
            e.preventDefault();
            return previousItem.focus();
        }

        if (!isInputFocused && e.key === 'Enter') {
            e.preventDefault();
            return focusedItem.click();
        }

        if (!isInputFocused) {
            e.preventDefault();
            removeList();
        }
    }

    const onInputChange = (e) => setInputValue(e.target.value);

    const filteredUsers = users?.filter(({fullname}) => fullname.toLowerCase().includes(inputValue.toLowerCase()));

    const handleSearchKeyboard = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            const firstUser = filteredUsers[0];
            if (!firstUser) return;

            onUserSelect(firstUser.id, firstUser.fullname, firstUser.email);
        }

        // Close the list if the user press the escape key or the input is empty and the user press backspace
        if (e.key === 'Escape' || (inputValue.length === 0 && e.key === 'Backspace')) {
            e.preventDefault();
            e.stopPropagation();
            removeList();
        }
    }

    return {
        listRef,
        users: filteredUsers,
        onUserSelect,
        handleKeyboardNavigation,
        inputValue,
        onInputChange,
        handleSearchKeyboard,
        handleBlurList,
    }
}
