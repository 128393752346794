import UserService from "api/user/user";
import {randomId} from "v4/utils";
import {generateUrl} from "v4/services/api.service";
import {USER_NOTIFICATION} from "v4/data/apiRoutes";
import {asyncDebounce} from "../../../../../../../../utils/debounce";

export function createActionTagSpan({
                                        userId,
                                        userFullname,
                                        userEmail,
                                        isDone = false,
                                        createdBy,
                                        createdByFullname,
                                        createdByEmail,
                                    }, editor) {
    const {id, fullname, email} = UserService.getUser();

    const tagId = randomId();
    const infos = {
        externalId: tagId,
        userId: userId,
        notificationType: 'user_tag',
        status: 'pending',
        userTag: {
            tagId,
            isDone,
            entityUrl: window.location.pathname.replace('edit/', ''),
            tagged: {
                userId: userId,
                fullname: userFullname,
                email: userEmail
            },
            taggedBy: {
                userId: createdBy ?? id,
                fullname: createdByFullname ?? fullname,
                email: createdByEmail ?? email
            }
        }
    };

    const actionTagSpan = editor.contentDocument.createElement('span');
    actionTagSpan.classList.add('user-tag');
    actionTagSpan.setAttribute('data-role', 'editor-action-tag');
    actionTagSpan.setAttribute('data-options', JSON.stringify(infos));
    actionTagSpan.innerHTML = `@${userFullname}`;

    return actionTagSpan;
}

export function renderEditorActionTag(editorActionTag, fieldName, getContent, isShow = false) {
    editorActionTag.contentEditable = 'false';
    const infos = JSON.parse(editorActionTag.getAttribute('data-options'));

    editorActionTag.innerHTML = `
        <label contenteditable="false">
            @${infos.userTag.tagged.fullname}
            ${isShow ? `<input type="checkbox" contenteditable="true" ${infos?.userTag?.isDone ? 'checked' : ''}>` : ''}
        </label>
    `;

    const inputCheckbox = editorActionTag.querySelector('input[type=checkbox]');

    if (!inputCheckbox) return;

    inputCheckbox.addEventListener('change', e => onActionTagCheck(editorActionTag, fieldName, getContent, e));
}

async function onActionTagCheck(editorActionTagElement, name, getContent, event) {
    const isChecked = event.currentTarget.checked;
    const infos = JSON.parse(editorActionTagElement.getAttribute('data-options'));

    editorActionTagElement.removeAttribute('data-options'); // Otherwise, the data-options attribute is not updated

    const newInfos = {
        ...infos,
        userTag: {
            ...infos.userTag,
            isDone: isChecked,
        }
    }

    editorActionTagElement.setAttribute('data-options', JSON.stringify(newInfos));

    const request = await asyncDebounce(() =>
        fetch(generateUrl(USER_NOTIFICATION, {id: newInfos.externalId}), {
            method: 'POST',
            headers: {
                'X-POL-AUTH': `Bearer ${UserService.getTokens().token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isDone: isChecked,
                ...((name && getContent) && {[name]: getContent()}),
            })
        })
    );

    if (!request.ok) {
        console.error('Error while updating action tag');
    }
}
