import React from 'react';
import PolDropdown from "v4/components/ui/PolDropdown/PolDropdown";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TASK} from "v4/data/entities";

const SUBSCRIPTION_TYPES = [
    {
        type: 'title',
        label: 'notifications',
    },
    {
        type: 'link',
        label: 'subscribe_tag_created_task',
        params: {
            subscriptionType: 'tag_created',
            entityType: TASK,
        }
    }
];

export default function NotificationsSubscriptionsConfigurationTools() {
    const {t} = usePolTranslation();

    return (
        <PolDropdown isHoverable
                     anchorInline="right"
                     summaryRender={({isHovered}) => (
                         <PolButton isActive={isHovered}>
                             <PolIcon icon="plus"/> {t('add')}
                         </PolButton>
                     )}
                     detailsList={SUBSCRIPTION_TYPES}
                     detailsListItemRender={({item}) => {
                         if (item.type === 'title') {
                             return <div className="pol-dropdown__details-title">{t(item.label)}</div>
                         }

                         if (item.type === 'link') {
                             return (
                                 <Link to={getLinkTo(item)}
                                       className="pol-dropdown__details-item">
                                     {t(item.label)}
                                 </Link>
                             )
                         }

                         return null;
                     }}
        />
    )
}

function getLinkTo({params}) {
    const getParamsPart = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');

    return `${routesBase.adminNotificationsSubscriptions}/add?${getParamsPart}`;
}
