import React from 'react';
import FormRender from "v4/components/form/FormRender/FormRender";
import FormRow from "v4/components/form/FormRender/components/FormRow/FormRow";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";

export default function TagCreatedTaskForm({formData, formId, onSubmit}) {
    return (
        <FormRender form={formData} formId={formId} onSubmit={onSubmit}>
            <PolColumns cols={2}>
                <FormRow fieldName="user_notification_subscription_tag_created_task[label]"/>
                <FormRow
                    fieldName="user_notification_subscription_tag_created_task[tagCreatedConfiguration][taskType]"/>
                <FormRow fieldName="user_notification_subscription_tag_created_task[subscribedUserIds]"/>
                <FormRow
                    fieldName="user_notification_subscription_tag_created_task[tagCreatedConfiguration][taggedByUserSectionId]"/>
            </PolColumns>
        </FormRender>
    )
}
