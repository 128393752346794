import viewBlob from "v4/utils/viewBlob";

export default function initGedPickerShow(container) {
    container
        .querySelectorAll('a[data-ged]')
        .forEach(renderGedPicker);
}

/**
 * @param {HTMLAnchorElement} anchor
 */
function renderGedPicker(anchor) {
    const fileIcon = anchor.ownerDocument.createElement('i');
    fileIcon.className = 'policon-file';
    anchor.prepend(fileIcon);

    anchor.addEventListener('click', (e) => {
        e.preventDefault();
        fetch(anchor.href)
            .then((response) => response.blob())
            .then(viewBlob);
    });
}
