import {
    renderEditorActionTag
} from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/utils/actionTagUtils";

export default function initActionTagShow(container, fieldName) {
    const getContent = () => {
        // Returns the content of the container (shadowRoot) but remove the <style> tag
        const childrenWithoutStyle = Array.from(container.children).filter(child => child.tagName !== 'STYLE');
        return childrenWithoutStyle.map(child => child.outerHTML).join('');
    }

    container
        .querySelectorAll('[data-role="editor-action-tag"]')
        .forEach(editorActionTag => renderEditorActionTag(editorActionTag, fieldName, getContent, true));
}
