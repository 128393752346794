import initActionTagShow from "v4/components/form/ShowField/components/TextareaRenderer/plugins/initActionTagShow";
import initGedPickerShow from "v4/components/form/ShowField/components/TextareaRenderer/plugins/initGedPickerShow";

/* eslint import/no-webpack-loader-syntax: off */
import iconsCss from "!!raw-loader!scss/components/pol-icon/style.css";
import ActionTagCss from "!!raw-loader!v4/components/form/Field/components/InputRichText/plugins/ActionTag/ActionTag.css"
import GedPickerCss from "!!raw-loader!v4/components/form/Field/components/InputRichText/plugins/GedPicker/GedPicker.css"
import PluginsTinyMCEVoter from "v4/voters/pluginsTinyMCEVoter";

const {
    canAccessUserTagPlugin
} = PluginsTinyMCEVoter();

export default function initPluginsForTextareaRenderer(shadowRoot, fieldName) {
    const styleForShadowDom = document.createElement('style');

    const cssArray = [ // Add all css in the array for the plugins
        iconsCss,
        GedPickerCss,
    ];

    if (canAccessUserTagPlugin()) {
        cssArray.push(ActionTagCss);
        initActionTagShow(shadowRoot, fieldName);
    }

    initGedPickerShow(shadowRoot);

    styleForShadowDom.innerHTML = cssArray.join('');
    shadowRoot.prepend(styleForShadowDom);
}
