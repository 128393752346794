import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import TableTheadTh from "v4/components/ui/Table/components/TableThead/components/TableTheadTh/TableTheadTh";
import useTableThead from "v4/components/ui/Table/components/TableThead/hooks/useTableThead";
import './TableThead.scss';

export default React.memo(function TableThead({theadData, actionsColumn, theadDataTrBeforeRender, onSort}) {
    const {t} = usePolTranslation();
    const {sortOrders, onCellSort, currentSortHeader} = useTableThead(theadData, onSort);

    return (
        <thead>
        {theadDataTrBeforeRender && (
            <tr className="table__specific-tr">
                {
                    theadData.map(header => {
                        const key = typeof header === 'object' ? header.key : header;
                        return (
                            <th className="table__specific-th" key={key}>
                                {theadDataTrBeforeRender(key)}
                            </th>
                        )
                    })
                }
                {actionsColumn && <th className="table__specific-th">{t('actions')}</th>}
            </tr>
        )}
        <tr className="table-thead">
            {
                theadData?.length > 0
                    ? <>
                        {theadData.map(header => {
                            const key = typeof header === 'object' ? header.key : header;
                            return (
                                key === 'actions'
                                    ? <th key={key}><span>{t('Actions')}</span></th>
                                    : <TableTheadTh key={key}
                                                    header={header}
                                                    currentSortHeader={currentSortHeader}
                                                    sortOrder={sortOrders[key]}
                                                    onCellSort={onCellSort}
                                                    isSortable={!!onSort}/>
                            )
                        })}
                        {actionsColumn && <th>{t('actions')}</th>}
                    </>
                    : <th></th>
            }

        </tr>
        </thead>
    )
});
