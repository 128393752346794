import i18n from "i18next";

/**
 * Init the action tag plugin
 * @param {import("tinymce/tinymce").Editor} editor
 * @param {Function} openGedPicker
 *
 * @returns {import("tinymce/tinymce").Plugin}
 */
export default function initGedPicker(editor, openGedPicker) {
    const addAnchorInEditor = (url, text) => {
        editor.insertContent(`<a href="${url}" data-ged>${text}</a>`);
    }

    editor.ui.registry.addButton('ged-picker', {
        icon: 'new-document',
        tooltip: i18n.t('import_docs'),
        onAction: () => openGedPicker(addAnchorInEditor, ['*'], 'url')
    });

    return {
        getMetadata: () => ({
            name: 'ged-picker',
            url: process.env.REACT_APP_HOST_API
        })
    }
}
