import {TAB_NAME_ENTITY} from "v4/data/tabNames";
import UserService from "api/user/user";
import {routesBase} from "v4/data/appRoutes";
import {CONTACT, PRODUCT, PROSPECT, QUOTE, TASK} from "v4/data/entities";

export default function getEntityFromPath(pathname) {
    const pathParts = pathname.split('/');

    const tabName = pathParts.find(part => Object.keys(TAB_NAME_ENTITY).includes(part));

    let entity = TAB_NAME_ENTITY[tabName];

    if (!entity) {
        const currentTab = UserService.getFlatTabs().find(({slug}) => pathParts.includes(slug));
        entity = currentTab?.searchType;
    }

    if (!entity) {
        pathParts.forEach(part => {
            switch (part) {
                case routesBase.baseProspect.slice(1):
                    entity = PROSPECT;
                    break;
                case routesBase.baseContact.slice(1):
                    entity = CONTACT;
                    break;
                case routesBase.baseTask.slice(1):
                    entity = TASK;
                    break;
                case routesBase.baseQuote.slice(1):
                    entity = QUOTE;
                    break;
                case routesBase.baseArticle.slice(1):
                    entity = PRODUCT;
                    break;
                default:
                    break;
            }
        });
    }

    return entity;
}
