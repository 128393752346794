// TinyMCE so the global var exists
import tinymce from 'tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// Default plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/paste';

/* eslint import/no-webpack-loader-syntax: off */
import ActionTagCss from "!!raw-loader!v4/components/form/Field/components/InputRichText/plugins/ActionTag/ActionTag.css"
import GedPickerCss from "!!raw-loader!v4/components/form/Field/components/InputRichText/plugins/GedPicker/GedPicker.css"

// Custom Plugins
import initActionTag from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/initActionTag"
import initGedPicker from "v4/components/form/Field/components/InputRichText/plugins/GedPicker/initGedPicker";
import PluginsTinyMCEVoter from "v4/voters/pluginsTinyMCEVoter";

const {
    canAccessUserTagPlugin
} = PluginsTinyMCEVoter();


/**
 * @param {{name: string, items: string[]}[]} toolbar
 * @param {string} name
 * @param {function} openGedPicker
 */
export default function initPlugins({toolbar, fieldName, openGedPicker}) {
    // Define TinyMCE default plugins list
    const defaultPlugins = [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'print',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'paste',
        'code',
        'help',
        'wordcount',
    ];

    /** @type {{name: string, action: (editor: import("tinymce/tinymce").Editor) => import("tinymce/tinymce").Plugin, toolbarOptions?: {index: number, append: boolean}}[]} */
    const customPlugins = [
        {
            name: 'ged-picker',
            action: (editor) => initGedPicker(editor, openGedPicker),
            toolbarOptions: {
                index: toolbar.length - 2,
                append: false,
            },
            css: GedPickerCss,
        },
    ];

    if (canAccessUserTagPlugin()) {
        customPlugins.push({
            name: 'action-tag',
            action: (editor) => initActionTag(editor, fieldName),
            css: ActionTagCss,
        });
    }

    const toolbarWithPlugins = Array.from(toolbar);
    const additionalContentCss = [];

    // Handle Custom Plugins
    customPlugins.forEach(({name, action, toolbarOptions, css}) => {
        tinymce.PluginManager.add(name, action);

        if (toolbarOptions) {
            const {index, append} = toolbarOptions;

            if (append) {
                toolbarWithPlugins[index].items.push(name);
            } else {
                toolbarWithPlugins.splice(index + 1, 0, {name: '', items: [name]});
            }
        }

        if (css) additionalContentCss.push(css);
    });

    // Return the complete plugins list and needed toolbars actions
    return {
        toolbarWithPlugins,
        pluginList: [...defaultPlugins, ...customPlugins.map(({name}) => name)],
        additionalContentCss: additionalContentCss.join('')
    }
}
