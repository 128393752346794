import React from 'react';
import {Link} from "react-router-dom";

export default function PolButton({type = 'button', className = "btn-primary", isDisabled, isActive, to, children, ...buttonProps}) {
    if (to) {
        return (
            <Link to={to}
                  disabled={isDisabled}
                  className={`btn ${className} ${isActive ? 'is-active' : ''}`}
                  {...buttonProps}>
                {children}
            </Link>
        )
    }

    return (
        <button type={type}
                disabled={isDisabled}
                className={`btn ${className} ${isActive ? 'is-active' : ''}`}
                {...buttonProps}>
            {children}
        </button>
    )
}
