import useToggle from "v4/hooks/useToggle";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {NOTIFICATIONS_SUBSCRIPTIONS_ITEM} from "v4/data/apiRoutes";
import {useEffect} from "react";

export default function useListingActionsColumn(rowInfos, refreshListing) {
    const [isConfirmDeleteOpen, toggleConfirmDelete] = useToggle();
    const [{isLoading: isDeleteLoading, isError, isFinished}, fetchDelete] = useFetch();

    const handleDelete = () => {
        fetchDelete({
            url: generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_ITEM, {id: rowInfos.id}),
            config: {method: 'DELETE'},
        })
    }

    useEffect(() => {
        if (isFinished && !isError) {
            refreshListing();
        }
    }, [isError, isFinished, refreshListing]);

    return {
        isConfirmDeleteOpen,
        toggleConfirmDelete,
        isDeleteLoading,
        handleDelete,
    }
}
