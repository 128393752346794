import React, {createContext, useCallback, useContext, useMemo, useRef} from 'react';

const defaultFormRenderContext = {
    form: {},
    fields: [],
    renderedFields: {},
    setFieldRendered: () => {},
}

const formRenderContext = createContext(defaultFormRenderContext);

export function useFormRenderContext() {
    return useContext(formRenderContext);
}

export default function FormRenderProvider({form, children}) {
    const state = useMemo(() => ({
        form,
        fields: Object.entries(form.results).map(([key, value]) => {
            return {
                ...value,
                name: key,
            }
        }),
    }), [form]);
    const renderedFields = useRef(state.fields.reduce((acc, field) => {
        acc[field.name] = false;
        return acc;
    }, {}));

    const setFieldRendered = useCallback((fieldName) => {
        renderedFields.current[fieldName] = true;
    }, []);

    const formRenderContextValue = useMemo(() => ({
        ...state,
        renderedFields: renderedFields.current,
        setFieldRendered,
    }), [setFieldRendered, state]);

    return (
        <formRenderContext.Provider value={formRenderContextValue}>
            {children}
        </formRenderContext.Provider>
    )
}
