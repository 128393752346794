import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useTableTheadTh
    from "v4/components/ui/Table/components/TableThead/components/TableTheadTh/hooks/useTableTheadTh";

export default function TableTheadTh({header, sortOrder, currentSortHeader, onCellSort, isSortable}) {
    const {t} = usePolTranslation();
    const {handleSort} = useTableTheadTh(header, sortOrder, onCellSort);
    const key = typeof header === 'object' ? header.key : header;
    const label = typeof header === 'object' ? header.label : header;

    let className = isSortable ? 'sortable' : '';
    if (key === currentSortHeader) {
        className += ' sorted';
    }

    return (
        <th data-header={key}
            data-order={sortOrder}
            onClick={handleSort}
            className={className}>
            <span dangerouslySetInnerHTML={{__html: t(label)}}/>
        </th>
    )
}
