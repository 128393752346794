import React from 'react';
import usePolDropdown from "v4/components/ui/PolDropdown/hooks/usePolDropdown";
import './PolDropdown.scss';
import PolDropdownDetails from "v4/components/ui/PolDropdown/components/PolDropdownDetails/PolDropdownDetails";

export default function PolDropdown({
                                        className = '',
                                        summaryRender,
                                        detailsRender,
                                        detailsList = [],
                                        detailsListItemRender,
                                        detailsListItemOnClick,
                                        anchorInline = 'left',
                                        isHoverable = false,
                                    }) {
    const {
        isOpen,
        toggleIsOpen,
        isHovered,
        handleMouseEnter,
        handleMouseLeave,
    } = usePolDropdown();

    return (
        <div className={`pol-dropdown ${className} ${isHoverable ? 'is-hoverable' : ''}`}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
            <div className="pol-dropdown__summary" onClick={toggleIsOpen}>
                {summaryRender({isHovered, isOpen, toggleIsOpen})}
            </div>
            <div className={`pol-dropdown__content ${isOpen ? 'is-open' : ''}`}
                 style={{
                     ...(anchorInline === 'left' && {left: 0}),
                     ...(anchorInline === 'right' && {right: 0}),
                 }}>
                {detailsRender
                    ? detailsRender({isHovered, isOpen, toggleIsOpen, detailsList})
                    : <PolDropdownDetails detailsList={detailsList}
                                          detailsListItemRender={detailsListItemRender}
                                          detailsListItemOnClick={detailsListItemOnClick}
                                          toggleIsOpen={toggleIsOpen}/>
                }
            </div>
        </div>
    )
}
