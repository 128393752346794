export default function tableResultsResponseToTableRows(results) {
    if (!results) return null;

    return results.map(({fields, metadata}, index) => {
        const {id, ...restMetadata} = metadata ?? {};

        return {
            id: id ?? index,
            ...fields.reduce((acc, {key, value}) => {
                acc[key] = value;
                return acc;
            }, {}),
            metadata: restMetadata
        };
    });
}
