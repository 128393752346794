import {useCallback} from "react";

export default function useTableTheadTh(header, sortOrder, onCellSort) {
    const key = typeof header === 'object' ? header.key : header;

    const handleSort = useCallback(() => {
        onCellSort(key, sortOrder === 'asc' ? 'desc' : 'asc');
    }, [sortOrder, onCellSort, key]);

    return {handleSort};
}
