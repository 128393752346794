import React, {useEffect, useRef} from 'react';
import sanitizeRichText from "v4/utils/sanitizeRichText";
import {generateUrl} from "v4/services/api.service";
import {FILES_VIEW} from "v4/data/apiRoutes";
import initPluginsForTextareaRenderer from "v4/components/form/ShowField/components/TextareaRenderer/plugins/plugins";

export default function TextareaRenderer({fieldName, value, className, allInputs}) {
    const shadowDomRef = useRef(null);
    useEffect(() => {
        if (!value || !shadowDomRef.current) return;

        try {
            shadowDomRef.current.attachShadow({mode: 'open'});
            const shadowRoot = shadowDomRef.current.shadowRoot;
            shadowRoot.replaceChildren(...replaceImgSrc(sanitizeRichText(value), allInputs)); // Sanitize the html and replace the img src by the blob url
            initPluginsForTextareaRenderer(shadowRoot, fieldName);
        } catch (e) {
        }
    }, [allInputs, fieldName, shadowDomRef, value]);

    return (
        <span className={className} ref={shadowDomRef}/>
    )
}

function replaceImgSrc(html, allInputs) {
    if (!html) return [];

    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = html;
    const files = allInputs?.find(({key}) => key === 'customerFiles')?.value ?? [];
    if (!files) return tmpElement.children;

    const images = tmpElement.querySelectorAll('img[src^="cid:"]');
    images.forEach(async img => {
        const cid = img.getAttribute('src')?.replace('cid:', '');
        const file = files.find(({externalId}) => externalId === cid);
        if (file) {
            const fetchedImg = await fetch(generateUrl(FILES_VIEW, {id: file.id}));
            const blobFetchedImg = await fetchedImg.blob();
            img.src = URL.createObjectURL(blobFetchedImg);
        }
    });

    return tmpElement.children;
}
