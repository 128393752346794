import React from 'react';
import useDownloadLink from "v4/components/utils/DownloadLink/hooks/useDownloadLink";
import {PolIcon} from "../../../PolIcon/policon";
import {onDoubleClickHandler} from "../../../../utils/clicHandler";
import {getApiUrl} from "../../../../routes";

export const FileGrid = ({setInputValue, inputValue, handleEditedFile, itemToEdit, onChangeItem, fileName, fileID, icon, handleClickOnEdit, onFileItemDoubleClick}) => {
    const {handleDoubleClick} = useDownloadLink(fileName);
    const id = fileID;
    return (
        <>
            <PolIcon onDoubleClick={(e) => onDoubleClickHandler(() => {
                const url = process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', id);
                // Si on est pas en mode édition
                if (itemToEdit !== id && !onFileItemDoubleClick) {
                    handleDoubleClick(url)
                }
                if (onFileItemDoubleClick) {
                    onFileItemDoubleClick(url, fileName)
                }
            })} icon={icon}/>
            {itemToEdit === id ? (
                <>
                    <input className={'ged-component__input-rename'} onBlur={e => {
                        handleEditedFile({
                            oldName: fileName,
                            newName: inputValue,
                            id: id
                        })
                    }} onKeyPress={(e) => {
                        if (e.charCode === 13) {
                            handleEditedFile({
                                oldName: fileName,
                                newName: inputValue,
                                id: id
                            })
                        }
                    }} onChange={(e) => {
                        setInputValue(e.target.value)
                    }} type={"text"} defaultValue={inputValue}/>
                </>
            ) : <p
                onDoubleClick={(e) => onDoubleClickHandler(() => {
                    if (itemToEdit !== id) {
                        onChangeItem({
                            fileName: fileName,
                            id: id
                        }, null, null, 'single', 'container', true)
                    } else {
                        handleClickOnEdit();
                    }
                })}
                className={'ged-component__document-name'}>{inputValue}</p>}
        </>
    )
}
