import React from 'react';
import {useFormRenderContext} from "v4/components/form/FormRender/context/FormRenderContext";
import FormRow from "v4/components/form/FormRender/components/FormRow/FormRow";

export default function FormRest() {
    const {fields} = useFormRenderContext();

    return (
        // No need to filter out the fields that are already rendered as the FormRow component will handle that
        fields.map(field => <FormRow key={field.name} fieldName={field.name}/>)
    )
}
