import React, {useRef} from 'react';
import FieldWrapper from "v4/components/form/FieldWrapper/FieldWrapper";
import {useFormRenderContext} from "v4/components/form/FormRender/context/FormRenderContext";

/**
 *
 * @param {String} fieldName
 */
export default function FormRow({fieldName}) {
    const {fields, setFieldRendered, renderedFields} = useFormRenderContext();
    const canRender = useRef(renderedFields[fieldName]);
    const field = fields.find(field => field.name === fieldName && !canRender.current);
    setFieldRendered(fieldName);

    if (!field) return null;

    return (
        <FieldWrapper key={field.id ?? field.name}
                      name={field.name}
                      field={field}/>
    )
}
