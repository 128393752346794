import {useEffect, useRef} from "react";
import useFetch from "v4/hooks/useFetch";
import {downloadBlob} from "v4/utils";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function useDownloadLink(fileName) {
    const {t} = usePolTranslation();
    const {addInfoNotification} = useNotification();
    const _fileName = useRef(fileName);
    const [{data, isLoading}, fetchLink] = useFetch('blob');

    const handleClick = (e) => {
        e.preventDefault();
        const {href} = e?.currentTarget ?? e?.target ?? {};

        if (!href) return;

        fetchLink({url: href});
        addInfoNotification(t('fetching_file'));
    }

    const handleDoubleClick = (href, forceFileName) => {
        if (!href) return;
        if (forceFileName) _fileName.current = forceFileName;

        fetchLink({url: href})
    }

    const preventClick = (e) => e.preventDefault();

    useEffect(() => {
        _fileName.current = fileName
    }, [fileName]);

    useEffect(() => {
        if (data) downloadBlob(data, _fileName.current ?? "");
    }, [data]);

    return {
        handleClick,
        handleDoubleClick,
        preventClick,
        isLoading,
    };
}
