import {useCallback, useState} from 'react';

/**
 * @param {boolean} initialValue=false - initial value of the toggle
 * @returns {[boolean, (forceBool?: boolean) => void]} - the state, the toggle and the force functions
 */
export default function useToggle(initialValue = false) {
    const [isOpen, setIsOpen] = useState(initialValue);

    const toggle = useCallback((forceBool) => setIsOpen(wasOpen => {
        if (typeof forceBool === 'boolean') {
            return forceBool;
        }
        return !wasOpen
    }), []);

    return [isOpen, toggle];
}
