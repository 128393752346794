import React from "react";
import CrudRouteHandler from "v4/components/utils/CRUDRouteHandler/CRUDRouteHandler";
import NotificationsSubscriptionsConfigurationListing
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationListing/NotificationsSubscriptionsConfigurationListing";
import NotificationsSubscriptionsConfigurationForm
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationForm/NotificationsSubscriptionsConfigurationForm";

export default function NotificationsSubscriptionsConfiguration() {
    return (
        <CrudRouteHandler
            addRender={() => <NotificationsSubscriptionsConfigurationForm/>}
            editRender={id => <NotificationsSubscriptionsConfigurationForm id={id}/>}
            listRender={() => <NotificationsSubscriptionsConfigurationListing/>}
        />
    );
}
