import useAutoFetch from "v4/hooks/useAutoFetch";
import {generateUrl} from "v4/services/api.service";
import {
    NOTIFICATIONS_SUBSCRIPTIONS_ADD,
    NOTIFICATIONS_SUBSCRIPTIONS_EDIT_FORM,
    NOTIFICATIONS_SUBSCRIPTIONS_FORM,
    NOTIFICATIONS_SUBSCRIPTIONS_ITEM
} from "v4/data/apiRoutes";
import {useHistory} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import jsonToFormData from "v4/utils/jsonToFormData";
import useFetch from "v4/hooks/useFetch";
import {useEffect} from "react";

const formId = 'user_notification_subscription';

export default function useNotificationsSubscriptionsConfigurationForm(id) {
    const {t} = usePolTranslation();
    const {location: {search}, replace} = useHistory();
    const searchParams = new URLSearchParams(search);
    const {addSuccessNotification, addErrorNotification} = useNotification();

    if (!id && !searchParams.has('subscriptionType')) {
        addErrorNotification(t('page_not_found'));
        replace(routesBase.adminNotificationsSubscriptions);
    }

    const [
        subscriptionType,
        entityType,
    ] = [
        searchParams.get('subscriptionType'),
        searchParams.get('entityType'),
    ];

    const url = id
        ? generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_EDIT_FORM, {id})
        : generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_FORM, {
            subscriptionType,
            ...(entityType && {entityType}),
        });
    const {data, isLoading} = useAutoFetch(url, id);
    const formData = data?.['hydra:member'] ?? {};

    const [{isFinished, isError, isLoading: isSaveLoading}, fetchSave] = useFetch();
    const onSubmit = (submittedData) => {
        const url = id
            ? generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_ITEM, {id})
            : generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_ADD, {
                subscriptionType,
                ...(entityType && {entityType}),
            });
        fetchSave({
            url,
            config: {
                method: id ? 'PUT' : 'POST',
                body: jsonToFormData(submittedData),
                hasToPatchSymfony: true,
            }
        });
    }

    useEffect(() => {
        if (isFinished && !isError) {
            addSuccessNotification(t('success'));
            replace(routesBase.adminNotificationsSubscriptions);
        }
    }, [addSuccessNotification, isError, isFinished, replace, t]);

    return {
        formId,
        formData,
        formType: formData.metadata?.formType,
        onSubmit,
        isLoading,
        isSaveLoading,
    }
}
