import useToggle from "v4/hooks/useToggle";

export default function usePolDropdown() {
    const [isOpen, toggleIsOpen] = useToggle();
    const [isHovered, toggleIsHovered] = useToggle();

    const handleMouseEnter = () => {
        toggleIsHovered(true);
    }

    const handleMouseLeave = () => {
        toggleIsHovered(false);
    }

    return {
        isOpen,
        toggleIsOpen,
        isHovered,
        handleMouseEnter,
        handleMouseLeave,
    }
}
