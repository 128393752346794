import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import useDownloadLink from "v4/components/utils/DownloadLink/hooks/useDownloadLink";
import {getApiUrl} from "../../routes";
import {gedData} from '../../api/ged/get';
import {Folder} from "./_partials/folder/folder";
import {File} from "./_partials/file/file";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {mergeAndSortFiles} from "../../utils/gedDocumentSorter";
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {PostService} from "../../api/datas/post";
import Loading from "../Loading/Loading";
import {PolIcon} from "../PolIcon/policon";
import {onDoubleClickHandler, onSingleClickHandler} from "../../utils/clicHandler";

export const CurrentDirectory = (props) => {
    let {
        directoryList, currentFolder, t, gedData, activeView, activeSort, getType,
        isLoading, setLoading, itemToEdit, onChangeItem, inputValue, currentDirectoryList, isReadOnly,
        onFileItemDoubleClick, expansionAllowed = ['*']
    } = props
    let [oldDirectoryList, setOldDirectoryList] = useState([])
    let [oldCurrentFolder, setOldCurrentFolder] = useState(null)
    const {handleDoubleClick} = useDownloadLink();

    const getStyle = (style, snapshot) => {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            transitionDuration: `0.001s`
        };
    }

    if (directoryList) {
        currentDirectoryList = mergeAndSortFiles(directoryList, activeSort)
    }

    const documentToRender = currentDirectoryList && currentDirectoryList.length ? (
        currentDirectoryList.map((item, index) => {

            if (item.folderName) {
                return (
                    <Fragment key={`fragment-${item.id}`}>
                        {activeView === 'grid' ? (
                            <Droppable key={`droppable-${item.id}`} isCombineEnabled
                                       droppableId={`droppable-${item.id}`}>
                                {(provided, snapshot) => (
                                    <div
                                        onClick={e => {
                                            if (itemToEdit !== item.id) {
                                                onChangeItem({
                                                    folderName: item.folderName,
                                                    id: item.id,
                                                    hierarchyLevel: currentFolder ? currentFolder.hierarchyLevel : 0
                                                }, currentFolder ? currentFolder.hierarchyLevel : 0, null, 'single', 'container');
                                            }
                                        }}
                                        className={snapshot.isDraggingOver ? 'select' : ''} {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        <Draggable key={item.id} draggableId={item.id} index={index}
                                                   isDragDisabled={isReadOnly}
                                        >
                                            {({innerRef, draggableProps, dragHandleProps}, snapshot) => (
                                                <div
                                                    title={item.folderName}
                                                    ref={innerRef}
                                                    {...dragHandleProps}
                                                    {...draggableProps}
                                                    style={getStyle(draggableProps.style, snapshot)}>
                                                    <Folder {...props} renderByCurrentDirectory={true}
                                                            folder={item}
                                                            hierarchyLevel={currentFolder ? currentFolder.hierarchyLevel : 0}/>
                                                </div>
                                            )}
                                        </Draggable>
                                        <span className={'hidden'}>
                                            {provided.placeholder}
                                        </span>
                                    </div>
                                )}
                            </Droppable>) : (
                            <Droppable key={`droppable-${item.id}`} isCombineEnabled
                                       droppableId={`droppable-${item.id}`}>
                                {(provided, snapshot) => (
                                    <tr
                                        title={item.folderName}
                                        onClick={(e) => {
                                            const selectedItem = document.querySelector('.select');
                                            if (selectedItem !== null) {
                                                selectedItem.classList.remove('select');
                                            }
                                            e.currentTarget.classList.toggle('select');

                                            if (itemToEdit !== item.id) {
                                                onChangeItem({
                                                    folderName: item.folderName,
                                                    id: item.id,
                                                    hierarchyLevel: currentFolder ? currentFolder.hierarchyLevel : 0
                                                }, currentFolder ? currentFolder.hierarchyLevel : 0, null, 'single', 'container');
                                            }
                                        }}
                                        onDoubleClick={(e) => onDoubleClickHandler(() => {
                                            if (itemToEdit !== item.id) {

                                                onChangeItem({
                                                    folderName: item.folderName,
                                                    id: item.id,
                                                    hierarchyLevel: currentFolder ? currentFolder.hierarchyLevel + 1 : 0
                                                }, currentFolder ? currentFolder.hierarchyLevel + 1 : 0);
                                            }
                                        })}
                                        className={`${snapshot.isDraggingOver ? 'selected' : ''}`}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        <Draggable key={item.id} draggableId={item.id} index={index}
                                                   isDragDisabled={isReadOnly}>
                                            {({innerRef, draggableProps, dragHandleProps}, snapshot) => (
                                                <>
                                                    <td
                                                        className={'table-ged__document-name'}
                                                        ref={innerRef}
                                                        {...dragHandleProps}
                                                        {...draggableProps}
                                                        style={getStyle(draggableProps.style, snapshot)}>
                                                        <PolIcon icon={'folder'}/>
                                                    </td>
                                                    <Folder {...props} renderByCurrentDirectory={true}
                                                            folder={item}
                                                            hierarchyLevel={currentFolder ? currentFolder.hierarchyLevel : 0}/>
                                                </>
                                            )}
                                        </Draggable>
                                        <span className={'hidden'}>
                                            {provided.placeholder}
                                        </span>
                                    </tr>
                                )}
                            </Droppable>
                        )}
                    </Fragment>
                )
            }
            let fileFormat = item.fileName ? item.fileName.split('.').pop().toLowerCase() : '-';
            let icon;
            if(!(expansionAllowed?.includes('*') || expansionAllowed?.includes(fileFormat))) {
                return '';
            }

            switch (fileFormat) {
                case 'exe':
                    icon = 'exe';
                    break;
                case 'zip':
                    icon = 'zip';
                    break;
                case 'pdf':
                    icon = 'pdf';
                    break;
                case 'svg':
                    icon = 'svg';
                    break;
                case 'txt':
                    icon = 'txt';
                    break;
                default:
                    icon = 'file';
            }
            return (
                <Fragment key={`fragment-${item.id}`}>
                    {activeView === 'grid' ? (
                        <Droppable key={`droppable-${item.id}`} isCombineEnabled droppableId={`droppable-${item.id}`}>
                            {(provided, snapshot) => (
                                <div key={`div-${item.id}`} {...provided.droppableProps}
                                     ref={provided.innerRef}
                                     onClick={e => {
                                         const selectedItem = document.querySelector('.select');
                                         if (selectedItem !== null) {
                                             selectedItem.classList.remove('select');
                                         }
                                         e.currentTarget.classList.toggle('select');
                                         if (itemToEdit !== item.id) {
                                             onChangeItem({
                                                 fileName: item.realFileName,
                                                 id: item.id
                                             })
                                         }
                                     }}>
                                    <Draggable key={item.id} draggableId={item.id} index={index}
                                               isDragDisabled={isReadOnly}>
                                        {({innerRef, draggableProps, dragHandleProps}, snapshot) => (
                                            <div
                                                ref={innerRef}
                                                {...dragHandleProps}
                                                {...draggableProps}
                                                style={getStyle(draggableProps.style, snapshot)}
                                                title={item.realFileName}>
                                                <File {...props} icon={icon} file={item} fileName={item.realFileName}
                                                      fileID={item.id}
                                                      onFileItemDoubleClick={onFileItemDoubleClick}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                    <span className={'hidden'}>
                                        {provided.placeholder}
                                    </span>
                                </div>)}</Droppable>
                    ) : (
                        <Droppable key={`droppable-${item.id}`} isCombineEnabled droppableId={`droppable-${item.id}`}>
                            {(provided, snapshot) => (
                                <tr key={`tr-${item.id}`}
                                    className={`${snapshot.isDraggingOver ? 'selected' : ''}`} {...provided.droppableProps}
                                    onDoubleClick={(e) => onDoubleClickHandler(() => {
                                        const url = process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', item.id);
                                        // Si on est pas en mode édition
                                        if (itemToEdit !== item.id && !onFileItemDoubleClick) {
                                            handleDoubleClick(url, item.fileName)
                                        }

                                        if (onFileItemDoubleClick) {
                                            onFileItemDoubleClick(url, item.fileName)
                                        }
                                    })}
                                    onClick={(e) => {
                                        const target = e.currentTarget;
                                        onSingleClickHandler(() => {
                                            const selectedItem = document.querySelector('.select');
                                            if (selectedItem !== null) {
                                                selectedItem.classList.remove('select');
                                            }
                                            target.classList.toggle('select');
                                            if (itemToEdit !== item.id) {
                                                onChangeItem({
                                                    fileName: inputValue,
                                                    id: item.id
                                                })
                                            }
                                        })
                                    }}
                                    ref={provided.innerRef}>
                                    <Draggable key={item.id} draggableId={item.id} index={index}
                                               isDragDisabled={isReadOnly}>
                                        {({innerRef, draggableProps, dragHandleProps}, snapshot) => {

                                            return (
                                                <>
                                                    <td
                                                        title={item.realFileName}
                                                        className={'table-ged__document-name'}
                                                        ref={innerRef}
                                                        {...dragHandleProps}
                                                        {...draggableProps}
                                                        style={getStyle(draggableProps.style, snapshot)}>
                                                        <PolIcon icon={icon}/>
                                                    </td>
                                                    <File icon={icon} {...props} file={item}
                                                          fileName={item.realFileName}
                                                          fileID={item.id}
                                                          onFileItemDoubleClick={onFileItemDoubleClick}
                                                    />
                                                </>
                                            )
                                        }}
                                    </Draggable>
                                    <span className={'hidden'}>
                                        {provided.placeholder}
                                    </span>
                                </tr>)}</Droppable>
                    )}
                </Fragment>
            )
        })
    ) : t('folder_empty')

    useEffect(() => {
        if (!directoryList || !oldDirectoryList
            || ((!currentFolder && oldCurrentFolder) || (currentFolder && oldCurrentFolder && currentFolder !== oldCurrentFolder) || (currentFolder && !oldCurrentFolder))
        ) {
            setLoading(true)
            let url = getApiUrl('ged', 'list');
            if (currentFolder) {
                url = getApiUrl('ged', 'listFromFolder').replace('{id}', currentFolder.id)
            }

            setOldCurrentFolder(currentFolder)
            setOldDirectoryList(directoryList)

            if (getType !== 'search') {
                gedData({
                    method: 'GET',
                    url: url,
                    type: 'list',
                    isRoot: !currentFolder,
                    callback: () => {
                        setLoading(false)
                    }
                })
            } else {
                setLoading(false)
            }
        }

    }, [gedData, currentFolder, oldCurrentFolder, oldDirectoryList, directoryList, getType, setLoading])

    return (
        <>
            {isLoading && <Loading message={t("loading")} specificClass={"form-loader"}/>}

            <div className={"ged-component__currentDirectory-iconview"}>
                {activeView === 'grid' || !activeView ? (
                    <>
                        {documentToRender}
                    </>
                ) : (
                    <table className={'table-ged table-ged__table'}>
                        <thead>
                        <tr>
                            <th>{t('selection')}</th>
                            <th>{t('name')}</th>
                            <th>{t('createdAt')}</th>
                            <th>{t('size')}</th>
                            <th>{t('type')}</th>
                        </tr>
                        </thead>
                        <tbody className={'table-ged__body'}>
                        {documentToRender}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const mapStateToProps = ({ged: {directoryList, getType}}) => ({
    directoryList, getType
});

const mapDispatchToProps = dispatch => {
    return {
        gedData: (info) => dispatch(gedData(info)),
        postService: (info, t) => dispatch(PostService(info, t)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(CurrentDirectory));
