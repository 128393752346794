import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import FormRenderProvider from "v4/components/form/FormRender/context/FormRenderContext";
import FormRest from "v4/components/form/FormRender/components/FormRest/FormRest";
/**
 * @typedef {Object} Form
 * @property {Object} metadata
 * @property {Object} results
 */

/**
 * @param {Form} form
 * @param {import(react-hook-form).UseFormReturn} methods
 * @param {string} formId
 * @param {function} onSubmit
 * @param {boolean} hasToHideRest
 * @param {React.ReactNode} children
 */
export default function FormRender({form, methods, formId, onSubmit, hasToHideRest = false, children}) {
    const formRenderMethods = methods ?? useForm();

    if (!form?.results) return null;

    return (
        <FormRenderProvider form={form}>
            <FormProvider {...formRenderMethods}>
                <form id={formId}
                      onSubmit={formRenderMethods.handleSubmit(onSubmit)}>
                    {children}

                    {!hasToHideRest && <FormRest/>}
                </form>
            </FormProvider>
        </FormRenderProvider>
    )
}
