import React from 'react';
import {useParams} from "react-router-dom";
import DefaultCrudNotFound
    from "v4/components/utils/CRUDRouteHandler/components/DefaultCRUDNotFound/DefaultCRUDNotFound";

/**
 * @param {function} addRender
 * @param {function(id: string)} editRender
 * @param {function} listRender
 * @param {function} notFoundRender
 */
export default function CrudRouteHandler({addRender, editRender, listRender, notFoundRender}) {
    const {action, id} = useParams();

    if (action === 'add' && addRender) return addRender();

    if (action === 'edit' && editRender && id) return editRender(id);

    if (action) return notFoundRender ? notFoundRender() : <DefaultCrudNotFound/>;


    return listRender();
}
