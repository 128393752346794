import {useEffect} from "react";
import useFetch from "v4/hooks/useFetch";
import viewBlob from "v4/utils/viewBlob";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function useViewLink(href) {
    const {t} = usePolTranslation();
    const {addInfoNotification, addSuccessNotification} = useNotification();
    const [{data, isLoading}, fetchLink] = useFetch('blob');

    const handleClick = (e) => {
        e.preventDefault();

        if (!href) return;

        fetchLink({url: href});
        addInfoNotification(t('fetching_file'));
    }

    const preventClick = (e) => e.preventDefault();

    useEffect(() => {
        if (data) {
            addSuccessNotification(t('file_fetched'));
            viewBlob(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return {
        handleClick,
        preventClick,
        isLoading,
    };
}
