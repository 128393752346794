import React from 'react';
import Loader from "v4/components/ui/Loader/Loader";

import 'v4/components/ui/Table/components/TableTbody/TableTbody.scss'
import {createPortal} from "react-dom";

export default React.memo(function TableTbody({theadData, tableRows, actionsColumn, renderRow, renderCell, isLoading, isError}) {
    return (
        <tbody className={(tableRows?.length === 0 || tableRows === null) ? 'empty' : null}>
        {isLoading && (
            createPortal(
                <div className="listing-table-tbody__loader">
                    <Loader message="Chargement..."/>
                </div>
                , document.querySelector('.ui-table-wrapper'))
        )}
        {
            theadData?.length > 0 && tableRows?.length > 0
                ? !isError && tableRows?.map((row, index) => {
                if (renderRow) return renderRow(row, index);

                return (
                    <tr key={row?.id ?? index}>
                        {theadData.map(header => {
                            const key = typeof header === 'object' ? header.key : header;

                            return (
                                renderCell
                                    ? renderCell(key, row[key], index)
                                    : <td key={key}>{row[key]}</td>
                            )
                        })}
                        {actionsColumn && <td className="actions-column">{actionsColumn(row, index)}</td>}
                    </tr>
                )
            })
                : !isLoading && !isError && (
                <tr>
                    <td colSpan="9999">Aucune donnée à afficher</td>
                </tr>
            )
        }
        {isError &&
            <tr>
                <td id="table-error" colSpan="9999">Une erreur est survenue</td>
            </tr>
        }
        </tbody>
    )
})
