import React from 'react';
import useExportExternalAction from "v4/features/front/exportExternalAction/hooks/useExportExternalAction";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import SubmitButton from "v4/components/ui/SubmitButton/SubmitButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import PolButton from "v4/components/ui/PolButton/PolButton";

export default function ExportExternalAction({buttonText, formAction, formTarget, formMethod, entityId, toggleOpen}) {
    const {t} = usePolTranslation();
    const {data, isLoading} = useExportExternalAction(entityId);

    if (isLoading) return <InlineLoader/>;

    if (!data) return null;

    // Close the modal after 1 second if the form target is _blank and
    // not immediately because if the form is no longer in the DOM,
    // the form submission will not work, and so the new tab will not open.
    const onSubmit = () => setTimeout(toggleOpen, formTarget === '_blank' ? 1000 : 0);

    return (
        <form action={formAction} target={formTarget} method={formMethod} onSubmit={onSubmit}>
            <input type="hidden" name="data" value={JSON.stringify(data)}/>
            <p>{t('confirm-action')}</p>
            <div className="d-flex justify-content-end">
                <PolButton onClick={toggleOpen}>{t('cancel')}</PolButton>
                <SubmitButton isFull>{t(buttonText ?? 'send_mail')}</SubmitButton>
            </div>
        </form>
    )
}
