import useAutoFetch from "v4/hooks/useAutoFetch";
import {generateUrl} from "v4/services/api.service";
import {NOTIFICATIONS_SUBSCRIPTIONS_LIST} from "v4/data/apiRoutes";
import useToggle from "v4/hooks/useToggle";

export default function useNotificationsSubscriptionsConfigurationListing() {
    const [bool, refreshListing] = useToggle();
    const {data, isLoading} = useAutoFetch(generateUrl(NOTIFICATIONS_SUBSCRIPTIONS_LIST, {subscriptionType: 'tag_created'}), bool);
    const tableData = data?.['hydra:member'] ?? {};

    return {
        tableData,
        isLoading,
        refreshListing,
    }
}
