import React from 'react';
import Ged from "views/Ged/Ged";
import {PolIcon} from "components/PolIcon/policon";

import 'v4/pages/front/Ged/components/GedPopup/GedPopup.scss';
import {captureException} from "@sentry/react";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function GedPopup({callback, toggle, expansionAllowed, returnType}) {
    const {t} = usePolTranslation();
    const {addErrorNotification} = useNotification();

    const handleItemDoubleClick = async (gedItemUrl, fileName) => {
        switch (returnType) {
            case 'base64':
                const request = await fetch(gedItemUrl);
                const src = await request.blob();
                const reader = new FileReader();
                reader.readAsDataURL(src);
                reader.onerror = e => {
                    captureException(e, {
                        extra: {
                            message: 'Error while getting file from Ged',
                            url: gedItemUrl,
                            blob: src
                        }
                    });
                }
                reader.onloadend = e => {
                    toggle();

                    if (!e.target.result.startsWith('data:image')) {
                        return addErrorNotification(t('image_not_found'));
                    }

                    callback(reader.result, fileName);
                }
                break;
            case 'url':
            default:
                toggle();
                callback(gedItemUrl, fileName);
                break;
        }
    }

    return (
        <div className="ged-popup">
            <div className="ged-popup__window-border">
                <p>Sélectionnez un fichier</p>
                <PolIcon icon="close" onClick={toggle}/>
            </div>
            <Ged isReadOnly={true}
                 onFileItemDoubleClick={handleItemDoubleClick}
                 expansionAllowed={expansionAllowed}/>
        </div>
    )
}
