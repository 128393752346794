import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import {PolIcon} from "../PolIcon/policon";
import {getApiUrl} from "../../routes";
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {FilePond, registerPlugin} from 'react-filepond';
import {PostService} from "../../api/datas/post";
import {DeleteService} from "../../api/datas/delete";
import {setPopup} from "../../actions/content";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {gedData} from "../../api/ged/get";
import ReactTooltip from "react-tooltip";
import Sort from "./_partials/responsive/sort";
import PopupInfoConfirm from "../Popup/_popup_info_confirm";
import ViewLink from "v4/components/utils/ViewLink/ViewLink";

registerPlugin(FilePondPluginFileValidateSize);

export const HeaderGed = (props) => {
    //State
    let [uploadedFiles, setUploadedFiles] = useState([]);
    let [newFolderName, setNewFolderName] = useState(null);
    const [filesSize, setFilesSize] = useState(0);
    const [menuState, setMenuState] = useState(false);

    //Props & Variables
    const {
        deleteService, currentItem, handleDelete, postService, t, handleClickOnEdit, currentFolder, editedItem, gedData,
        activeView, setActiveView, activeSort, setActiveSort, setLoading, isReadOnly, onFileItemDoubleClick
    } = props
    let classNameOfDisable = '';

    //Fonctions
    /**
     * Envoie des fichiers à post.js qui gère l'envoie des fichiers à l'API
     * @param files
     */
    const handleSubmitImport =
        () => {
            if (filesSize < process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD * 1024 * 1024 && uploadedFiles.length) {
                const submittedData = {
                    customerFiles: uploadedFiles,
                    customerFolder: currentFolder ? "/api/customer_folders/" + currentFolder.id : null
                }
                postService({
                    url: getApiUrl('ged', 'uploadFile'),
                    submittedData: submittedData,
                    type: 'uploadFile',
                    refreshGed: true,
                    loader: 'ged',
                    callback: (id, fileName) => {
                        if (onFileItemDoubleClick) {
                            onFileItemDoubleClick(
                                process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', id),
                                fileName
                            )
                        }
                    }
                }, t)
                uploadedFiles = [];
                setUploadedFiles(uploadedFiles)
            }
        }

    /**
     * Créer un nouveau dossier
     */
    const handleSubmitImportNewFolder =
        () => {
            if (newFolderName && !isReadOnly) {
                const submittedData = {
                    folderName: newFolderName,
                    customerFolder: currentFolder ? "/api/customer_folders/" + currentFolder.id : null
                }

                postService({
                    url: getApiUrl('ged', 'postFolder'),
                    submittedData: submittedData,
                    type: 'uploadFile',
                    refreshGed: true,
                    loader: 'ged'
                }, t)

                setNewFolderName(null)
            }
        }


    const displayPopupImport = () => {
        const {togglePopup, t} = props;
        togglePopup({
            isOpen: true,
            target: 'import_item',
            content: (
                <PopupInfoConfirm
                    onDecline={() => togglePopup({})}
                    onAccept={() => {
                        handleSubmitImport();
                        togglePopup({});
                    }
                    }
                    acceptText={t('import')}
                >
                    <div className={"row ged-component__header-popup-content"}>

                        <div className={"col-12"}>
                            <FilePond
                                onupdatefiles={fileItems => {
                                    const files = [];
                                    let filesSize = 0;
                                    fileItems.forEach(fileItem => {
                                        files.push(fileItem.file);
                                        filesSize += fileItem.fileSize;
                                    })
                                    setFilesSize(filesSize);
                                    setUploadedFiles(files);
                                    uploadedFiles = files;
                                }} className={'filepond'}
                                maxTotalFileSize={`${process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD}MB`}
                                labelMaxTotalFileSizeExceeded={t('maximum_size_exceeded_label')}
                                labelMaxTotalFileSize={t('maximum_size_label')}
                                {...(isReadOnly
                                        ? {
                                            maxFiles: 1,
                                            allowMultiple: false,
                                            labelIdle: t('import_form_file')
                                        }
                                        : {
                                            allowMultiple: true,
                                            labelIdle: t('add_files')
                                        }
                                )}
                            />
                        </div>
                    </div>
                </PopupInfoConfirm>
            )
        });

    }

    const displayPopupNewFolder = () => {
        const {togglePopup} = props;
        if (!isReadOnly) {
            togglePopup({
                isOpen: true,
                target: 'new_folder',
                content: (
                    <PopupInfoConfirm
                        onDecline={() => togglePopup({})}
                        onAccept={() => {
                            handleSubmitImportNewFolder();
                            togglePopup({});
                        }
                        }
                        acceptText={t('validate')}
                    >
                        <div className={"row ged-component__header-popup-content"}>
                            <div className={"col-12"}>
                                <input className={'popup__input'} type={'text'} placeholder={'Nom du dossier'}
                                       onChange={(e) => {
                                           newFolderName = e.target.value;
                                           setNewFolderName(e.target.value)
                                       }}/>
                            </div>
                        </div>
                    </PopupInfoConfirm>
                )
            });
        }
    }

    const handleChangeViewFormat = () => {
        setActiveView(activeView === 'grid' ? 'table' : 'grid')
    }

    const handleClickOnDelete = () => {
        if (!isReadOnly && window.confirm(currentItem.folderName ? t('ged_delete_document') : t('ged_delete_file'))) {
            if (currentItem.fileName) {
                deleteService({
                    deleteUrl: getApiUrl('ged', 'deleteFile').replace('{id}', currentItem.id),
                    id: currentItem.id
                })
                handleDelete(false)
                return
            }
            deleteService({
                deleteUrl: getApiUrl('ged', 'deleteFolder').replace('{id}', currentItem.id)
            })
            handleDelete(true)
        }
    }

    if (Object.keys(currentItem).length === 0 || !currentItem.fileName) {
        classNameOfDisable += 'disabled'
    }

    const handleSearchInput = (value) => {
        setLoading(true)
        let url = getApiUrl('ged', 'searchWithoutFolder');
        if (currentFolder) {
            url = getApiUrl('ged', 'searchWithFolder').replace('{idDossier}', currentFolder.id)
        }
        if (!value.length) {
            url = getApiUrl('ged', 'list');
            if (currentFolder) {
                url = getApiUrl('ged', 'listFromFolder').replace('{id}', currentFolder.id)
            }
        }
        gedData({
            method: 'GET',
            url: url.replace('{valeur}', value),
            type: 'list',
            useAbortController: true,
            getType: value.length ? 'search' : null,
            callback: () => {
                setLoading(false)
            }
        })
    }

    //ComponentDidUpdate

    useEffect(() => {
        if (editedItem && !isReadOnly) {
            const editedData = {
                id: editedItem.id,
                realFileName: editedItem.newName,
                folderName: editedItem.folderName
            }
            if (currentFolder) {
                editedData.customerFolder = "/api/customer_folders/" + currentFolder.id
            }
            postService({
                putUrl: getApiUrl('ged', editedItem.folderName ? 'putFolder' : 'put').replace('{id}', editedData.id),
                submittedData: editedData,
                type: 'uploadFile',
                loader: 'ged'
            }, t)
        }
    }, [editedItem, currentFolder, postService, t, isReadOnly]);

    return (
        <div className={'row ged-component__header-container'}>
            <div className={'ged-component__header-title col-md-2 col-3'}>
                <p>{t('ged_title')}</p>
                <PolIcon icon={'folder-settings'} onClick={() => {
                    setMenuState(true);
                }}/>
                <div className={`${menuState ? 'open' : ''}`}>
                    <div className={'ged__nav-menu--responsive'}>
                        <PolIcon icon={'quit'} onClick={() => {
                            setMenuState(false)
                        }}/>
                        <p className={'ged__nav-menu__title'}>{t('ged_title')}</p>
                        <button className={`ged__nav-menu__link ${activeView === 'grid' ? 'active' : ''}`}
                                onClick={handleChangeViewFormat}
                                data-tip={t('icon_display')}>
                            <span>{t('icon_display')}</span>
                        </button>
                        <button className={`ged__nav-menu__link ${activeView === 'table' ? 'active' : ''}`}
                                onClick={handleChangeViewFormat}
                                data-tip={t('lines_display')}>
                            <span>{t('lines_display')}</span>
                        </button>
                        <Sort activeSort={activeSort} setActiveSort={setActiveSort} t={t}
                              className={"ged__nav-menu__link"} specificClassName={"ged__nav-menu__select"}/>
                        <button className={`ged__nav-menu__link`} onClick={() => {
                            displayPopupImport();
                            setMenuState(false)
                        }}><span>{t('import_docs')}</span></button>
                        <button className={`ged__nav-menu__link`} onClick={() => {
                            displayPopupNewFolder();
                            setMenuState(false);
                        }}><span>{t('create_folder')}</span></button>
                        <div className={"ged__nav-menu__link"}>
                            <PolIcon icon={'search'}/>
                            <input type={'text'} placeholder={t('find_document')}
                                   onChange={(e) => handleSearchInput(e.target.value)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'ged-component__header-button-container col-9 col-md-10'}>
                <div className={'ged-component__header-button-container-responsive'}>
                    <DownloadLink className={`${Object.keys(currentItem).length === 0 ? 'disabled' : null}`}
                                  href={process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', currentItem.id)}
                                  fileName={currentItem.fileName}>
                        <PolIcon icon={'download'}/>
                    </DownloadLink>
                    <button className={`${Object.keys(currentItem).length === 0 ? 'disabled' : null}`}
                            onClick={handleClickOnEdit}
                            data-tip={t('edit')}><PolIcon icon={'edit'}/></button>
                    <button className={`${Object.keys(currentItem).length === 0 ? 'disabled' : null}`}
                            onClick={handleClickOnDelete}
                            data-tip={t('delete')}><PolIcon icon={'bin'}/></button>
                </div>
                <div className={"ged-component__header-button-container-displayMode"}>
                    <button className={`${activeView === 'grid' ? 'active' : ''}`} onClick={handleChangeViewFormat}
                            data-tip={t('icon_display')}>
                        <PolIcon icon={"affichage-icone"}/>
                    </button>
                    <button className={`${activeView === 'table' ? 'active' : ''}`} onClick={handleChangeViewFormat}
                            data-tip={t('lines_display')}>
                        <PolIcon icon={"affichage-ligne"}/>
                    </button>
                </div>
                <Sort activeSort={activeSort} setActiveSort={setActiveSort} t={t}
                      className={"ged-component__header-button-container-select"}/>

                {!isReadOnly ? (
                    <>
                        <div className={"ged-component__header-button-container-action"}>
                            <button className={`${Object.keys(currentItem).length === 0 ? 'disabled' : null}`}
                                    onClick={handleClickOnEdit}
                                    data-tip={t('edit')}><PolIcon icon={'edit'}/></button>
                            <button className={`${Object.keys(currentItem).length === 0 ? 'disabled' : null}`}
                                    onClick={handleClickOnDelete}
                                    data-tip={t('delete')}><PolIcon icon={'bin'}/></button>
                        </div>
                    </>
                ) : null}
                <div className={"ged-component__header-button-container-upload"}>
                    {!isReadOnly && (
                        <DownloadLink className={classNameOfDisable}
                                      href={process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', currentItem.id)}
                                      fileName={currentItem.fileName}>
                            <PolIcon icon={'download'}/>
                            {t('download')}
                        </DownloadLink>
                    )}
                    <ViewLink className={`${classNameOfDisable} view-link`}
                              href={process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', currentItem.id)}>
                        <PolIcon icon="eye"/>
                        {t('view_file')}
                    </ViewLink>
                    <button onClick={displayPopupImport}><PolIcon icon={'export'}/>{t('import')}</button>
                </div>
                <div className={"ged-component__header-button-container-search"}>
                    <div>
                        <PolIcon icon={'search'}/>
                        <input type={'text'} placeholder={t('find_document')}
                               onChange={(e) => handleSearchInput(e.target.value)}/>
                    </div>
                </div>
                {!isReadOnly ? (
                    <div className={"ged-component__header-button-container-create"}>
                        <button onClick={displayPopupNewFolder}>
                            <PolIcon icon={"folder"}/>
                            {t('create_folder')}
                        </button>
                    </div>) : null}
            </div>
            <ReactTooltip effect="solid"/>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        deleteService: (info) => dispatch(DeleteService(info)),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        gedData: (info) => dispatch(gedData(info)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(HeaderGed));
