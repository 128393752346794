import React from 'react';
import useViewLink from "v4/components/utils/ViewLink/hooks/useViewLink";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewLink({href, className = "", children}) {
    const {t} = usePolTranslation();
    const {handleClick, isLoading, preventClick} = useViewLink(href);

    return (
        <a href={href}
           className={`${className} ${isLoading ? 'disabled' : ''}`}
           onClick={isLoading ? preventClick : handleClick}
           title={t('view_file')}>
            {children}
        </a>
    )
}
