import useAccessVoter from "v4/hooks/useAccessVoter";

export default function PluginsTinyMCEVoter() {
    const {isGranted} = useAccessVoter();

    const canAccessUserTagPlugin = () => {
        return isGranted('tag_use')
    }

    return {
        canAccessUserTagPlugin
    }
}
