import React from 'react';
import {render} from "react-dom";
import UserList from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/components/UserList/UserList";
import {
    renderEditorActionTag
} from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/utils/actionTagUtils";

/**
 * Init the action tag plugin
 * @param {import("tinymce/tinymce").Editor} editor
 * @param {string} fieldName
 *
 * @returns {import("tinymce/tinymce").Plugin}
 */
export default function initActionTag(editor, fieldName) {
    let canUseKeyboard = true;
    const toggleCanUseKeyboard = (forceBool) => canUseKeyboard = forceBool ?? !canUseKeyboard;

    editor.on('keydown', async (event) => {
        if (!canUseKeyboard) {
            event.preventDefault();
            return;
        }

        if (event.key === '@') {
            toggleCanUseKeyboard(false);
            const userListContainer = document.createElement('div');
            userListContainer.classList.add('user-list__container');
            editor.container.after(userListContainer);

            // Génère un rendu de liste pour la sélection d'un utilisateur
            render(
                <UserList editor={editor} toggleCanUseKeyboard={toggleCanUseKeyboard}/>,
                userListContainer
            );
        }
    });

    editor.on('SetContent', () => {
        editor.getBody().querySelectorAll('[data-role="editor-action-tag"]').forEach(editorActionTag => renderEditorActionTag(editorActionTag, fieldName));
    });

    editor.on('LoadContent', () => {
        editor.getBody().querySelectorAll('[data-role="editor-action-tag"]').forEach(editorActionTag => renderEditorActionTag(editorActionTag, fieldName));
    });

    return {
        getMetadata: () => ({
            name: 'action-tag',
            url: process.env.REACT_APP_HOST_API
        })
    };
}
