import React from 'react';
import useNotificationsSubscriptionsConfigurationListing
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationListing/hooks/useNotificationsSubscriptionsConfigurationListing";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Table from "v4/components/ui/Table/Table";
import NotificationsSubscriptionsConfigurationTools
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationTools/NotificationsSubscriptionsConfigurationTools";
import tableResultsResponseToTableRows from "v4/utils/tableResultsResponseToTableRows";
import ListingActionsColumn
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationListing/components/ListingActionsColumn/ListingActionsColumn";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function NotificationsSubscriptionsConfigurationListing() {
    const {t} = usePolTranslation();
    const {tableData, isLoading, refreshListing} = useNotificationsSubscriptionsConfigurationListing();

    return (
        <AdminCard
            title={t('list_configurations')}
            CardHeaderTools={() => <NotificationsSubscriptionsConfigurationTools/>}>
            <Table isLoading={isLoading}
                   theadData={tableData?.headers ?? []}
                   tableRows={tableResultsResponseToTableRows(tableData?.results)}
                   actionsColumn={(rowInfos) => (
                       <ListingActionsColumn rowInfos={rowInfos} refreshListing={refreshListing}/>
                   )}
            />
        </AdminCard>
    )
}
