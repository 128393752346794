import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useListingActionsColumn
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationListing/components/ListingActionsColumn/hooks/useListingActionsColumn";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {routesBase} from "v4/data/appRoutes";
import {PolIcon} from "components/PolIcon/policon";
import Confirm from "v4/components/ui/Confirm/Confirm";

export default function ListingActionsColumn({rowInfos, refreshListing}) {
    const {t} = usePolTranslation();
    const {
        isConfirmDeleteOpen,
        toggleConfirmDelete,
        isDeleteLoading,
        handleDelete,
    } = useListingActionsColumn(rowInfos, refreshListing);

    return (
        <>
            <PolButton to={`${routesBase.adminNotificationsSubscriptions}/edit/${rowInfos.id}`}>
                <PolIcon icon="edit"/>
            </PolButton>
            <PolButton className="btn-danger" onClick={toggleConfirmDelete}>
                <PolIcon icon="trash"/>
            </PolButton>

            <Confirm isOpen={isConfirmDeleteOpen}
                     isLoading={isDeleteLoading}
                     confirmAction={handleDelete}
                     closeAction={toggleConfirmDelete}>
                {t('confirm_delete')}
            </Confirm>
        </>
    )
}
