import React, {Fragment} from 'react';

export default function PolDropdownDetails({detailsList, detailsListItemRender, detailsListItemOnClick, toggleIsOpen}) {
    return (
        <div className="pol-dropdown__details">
            {detailsList.map((item, index) => (
                detailsListItemRender
                    ? (
                        <Fragment key={index}>
                            {detailsListItemRender({item, index, toggleIsOpen})}
                        </Fragment>
                    )
                    : (
                        <div key={index}
                             className="pol-dropdown__details-item"
                             onClick={() => detailsListItemOnClick(item, index)}>
                            {String(item)}
                        </div>
                    )
            ))}
        </div>
    )
}
