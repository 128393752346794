import React from 'react';
import useNotificationsSubscriptionsConfigurationForm
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationForm/hooks/useNotificationsSubscriptionsConfigurationForm";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import FormRender from "v4/components/form/FormRender/FormRender";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes";
import TagCreatedTaskForm
    from "v4/pages/admin/NotificationsSubscriptionsConfiguration/components/NotificationsSubscriptionsConfigurationForm/components/TagCreatedTaskForm/TagCreatedTaskForm";
import SubmitButton from "v4/components/ui/SubmitButton/SubmitButton";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";
import FormRest from "v4/components/form/FormRender/components/FormRest/FormRest";
import {PolIcon} from "components/PolIcon/policon";

export default function NotificationsSubscriptionsConfigurationForm({id}) {
    const {t} = usePolTranslation();
    const {
        formId,
        formType,
        formData,
        onSubmit,
        isLoading,
        isSaveLoading
    } = useNotificationsSubscriptionsConfigurationForm(id);

    return (
        <AdminCard
            beforeTitleRender={() => (
                <PolButton to={routesBase.adminNotificationsSubscriptions}
                           className="btn-primary mr-2">
                    <PolIcon icon="return"/>
                    {t('return')}
                </PolButton>
            )}
            title={t(id ? 'edit_configuration' : 'add_configuration')}
            CardHeaderTools={() => (
                <SubmitButton isLoading={isSaveLoading} formId={formId}>
                    {t(id ? 'edit' : 'add')}
                </SubmitButton>
            )}
        >
            <div className="mt-2">
                {isLoading
                    ? <InlineLoader/>
                    : getFormTypeRender(formId, formType, formData, onSubmit)
                }
            </div>
        </AdminCard>
    )
}

function getFormTypeRender(formId, formType, formData, onSubmit) {
    switch (formType) {
        case 'user_notification_subscription_tag_created_task':
            return <TagCreatedTaskForm formData={formData} formId={formId} onSubmit={onSubmit}/>;
        default:
            return (
                <FormRender form={formData} formId={formId} onSubmit={onSubmit}>
                    <PolColumns cols={2}>
                        <FormRest/>
                    </PolColumns>
                </FormRender>
            );
    }
}
