import useAutoFetch from "v4/hooks/useAutoFetch";
import {generateUrl} from "v4/services/api.service";
import {ENTITY_SERIALIZATION} from "v4/data/apiRoutes";
import {useLocation} from "react-router-dom";
import getEntityFromPath from "v4/utils/getEntityFromPath";
import {PROSPECT} from "v4/data/entities";

export default function useExportExternalAction(entityId) {
    const {pathname} = useLocation();

    const entity = getEntityFromPath(pathname) ?? PROSPECT;

    const {data, isLoading} = useAutoFetch(generateUrl(ENTITY_SERIALIZATION, {entity, id: entityId}), entityId);

    return {
        data,
        isLoading,
    }
}
