import {PolIcon} from "components/PolIcon/policon";
import Confirm from "v4/components/ui/Confirm/Confirm";
import React, {useEffect} from "react";
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import ViewLink from "v4/components/utils/ViewLink/ViewLink";

export default function DocumentsFile({file, handleRemoveFiles}) {
    // @todo: translations a mettre en place
    const {t} = usePolTranslation();
    const [isConfirmOpen, toggleConfirmOpen] = useToggle();
    const [{isError, isFinished}, fetchDelete] = useFetch();
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const deleteFile = () => {
        fetchDelete({
            url: file.deleteLink,
            config: {
                method: 'DELETE'
            }
        })
    }

    useEffect(() => {
        if (isError && isFinished) {
            addErrorNotification(t("Une erreur est survenue lors de la suppression du document."));
        }

        if(!isError && isFinished) {
            addSuccessNotification(t('La suppression du document a bien été effectuée.'));
            handleRemoveFiles(file);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isFinished])

    return (
        <div className="document">
            <p className="document-label">
                <PolIcon icon="file" className="file-icon"/>
                <span>{file.fileLabel ?? file.realFileName}</span>
            </p>

            {file?.downloadLink && (
                <>
                    <ViewLink href={file.downloadLink} className="document-icon document-view">
                        <PolIcon icon="eye"/>
                    </ViewLink>
                    <DownloadLink href={file.downloadLink} fileName={file?.fileLabel} className="document-icon document-download">
                        <PolIcon icon="download"/>
                    </DownloadLink>
                </>
            )}

            {file?.deleteLink &&
                <>
                    <label className="document-delete" onClick={toggleConfirmOpen} title={t('delete')}>
                        <PolIcon icon="close" className="delete-icon"/>
                    </label>
                    <Confirm title={t('delete')}
                             isOpen={isConfirmOpen}
                             cancelAction={toggleConfirmOpen}
                             confirmAction={deleteFile}>
                        <p>{t('Êtes-vous sur de vouloir supprimer le document ?')}</p>
                    </Confirm>
                </>
            }
        </div>
    )
}
