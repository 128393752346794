import React from 'react';
import './PolColumns.scss';

export default function PolColumns({gridTemplateColumns, cols = 1, gap = "1rem", className = '', children}) {
    return (
        <div className={`pol-columns ${className}`}
             style={{
                 gridTemplateColumns,
                 ...(cols > 1 && {'--cols': cols}),
                 gap,
             }}
        >
            {children}
        </div>
    )
}
