export default function jsonToFormData(json) {
    const formData = new FormData();

    const appendToFormData = (data, key) => {
        if (data === null || data === undefined) {
            formData.append(key, '');
        } else if (Array.isArray(data)) {
            data.forEach((subData, index) => {
                appendToFormData(subData, `${key}[${index}]`);
            });
        } else if (typeof data === 'object' && !(data instanceof File)) {
            Object.entries(data).forEach(([subKey, subData]) => {
                appendToFormData(subData, `${key}[${subKey}]`);
            });
        } else {
            formData.append(key, data);
        }
    };

    Object.entries(json).forEach(([key, data]) => {
        appendToFormData(data, key);
    });

    return formData;
}
