import React from 'react';

export const PolIcon = ({icon = '', onClick, onDoubleClick, className, color, size, ...props}) => {
    const style = {};

    if (color) style.color = color;
    if (size) style.fontSize = Number.isInteger(size) ? `${size}px` : size;

    return (
        <i className={`policon policon-${icon} ${className ?? ''}`}
           onClick={onClick}
           onDoubleClick={onDoubleClick}
           data-tip={props['data-tip']}
           data-for={props['data-for']}
           style={style}
        />
    )
};
