import React from 'react';
import useUserList
    from "v4/components/form/Field/components/InputRichText/plugins/ActionTag/components/UserList/hooks/useUserList";
import {useTranslation} from "react-i18next";
import "./UserList.css";

/**
 *
 * @param {import("tinymce/tinymce").Editor} editor
 * @param {function} toggleCanUseKeyboard
 */
export default function UserList({editor, toggleCanUseKeyboard}) {
    const {t} = useTranslation();
    const {
        listRef,
        users,
        onUserSelect,
        handleKeyboardNavigation,
        inputValue,
        onInputChange,
        handleSearchKeyboard,
        handleBlurList,
    } = useUserList(editor, toggleCanUseKeyboard);

    if (!users) return null;

    return (
        <form className="user-list"
              contentEditable={false}
              onKeyDown={handleKeyboardNavigation}
              onBlur={handleBlurList}
              ref={listRef}>
            <input type="text"
                   className="user-list__search"
                   value={inputValue}
                   onChange={onInputChange}
                   onKeyDown={handleSearchKeyboard}
                   placeholder={t('votre_recherche')}
                   contentEditable={true}
                   tabIndex="-1"/>
            {users.map(({fullname, id, email}) => (
                <div key={id}
                     tabIndex="-1"
                     className="user-list__item"
                     onClick={() => onUserSelect(id, fullname, email)}>
                    <span>{fullname}</span>
                </div>
            ))}
        </form>
    )
}
